h1.title,  
.tour-teaser-wrapper h1,
.tour-teaser-wrapper h2 {
    font-family:'Crimson Text', serif;
}

.subheader {
  text-transform: uppercase;
  color: $grey;
  a { 
    color: $grey;
  }
  letter-spacing: 1px;
  margin: 30px 0 10px 0;
  font-size: 0.7em;
}


@media (max-width: 575.98px) {
  //Manage Display Headers for Mobile Devices
  .display-1, .display-2, .display-3, .display-4 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.0;
  }

  .hero-section .subtitle {
    font-size:0.9rem !important;
  }
}
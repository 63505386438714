$white: #fff;
$grey: grey;
$grey-light: #c0c0c0;
$grey-very-light: #f0f0f0;
$grey-dark: #101010;
$green: #03a87c;
$text-color: #212529;

/*******************/
$bg-color: $grey-very-light;
//$bg-footer: $grey-dark;
$color-footer: $grey-light; //$white;

$btn-bg: #d0d0d0;

$color-facebook:#3b5998;
$color-twitter: #1da1f2;
$color-instagram: #833AB4;
$color-whatsapp:#25D366;
$color-email:#D32566;
.tour-teaser-wrapper {
  width:100%;
  height:300px;
  overflow:hidden;
}

.tour-teaser {
  width: 100%;
  height: 100%;
  /*background-size: 110%;*/
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition-timing-function: ease-in;
  transition: transform 0.2s;

  h2 {
    position: relative;
    z-index: 1;
  }
}

.tour-teaser-wrapper:hover
.tour-teaser {
  transform: scale(1.05);
}


.thumbnail {
  position: relative;
}

.caption {
  display: -webkit-box;
  display: -ms-flexbox;
  display:flex;

  -webkit-box-align: center; 
  -ms-flex-align: center; 
      align-items: center; 

      
  position: absolute;
  
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 4px;
  h1 {
    margin:auto;
  }
}
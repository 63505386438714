.vertical-timeline, .vertical-timeline * {
  box-sizing: initial !important;
}


@media only screen and (min-width: 800px) {
  .vertical-timeline.vertical-timeline--two-columns {
    margin-top: 3em;
    margin-bottom: 3em;
    width: 90%;
  }
}

@media only screen and (min-width: 800px){
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content {
      float: right;
  }
}

@media only screen and (min-width: 800px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
      margin-left: 0;
      padding: 1.5em;
      width: 35%;
    }
  }

  @media only screen and (min-width: 996px) {
    .vertical-timeline--two-columns .vertical-timeline-element-content {
        margin-left: 0;
        padding: 1.5em;
        width: 40%;
      }
    }

@media only screen and (min-width: 800px) {
  .vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
  }
}


@media only screen and  (min-width: 800px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
}

@media only screen and  (min-width: 996px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
}

@media only screen and (min-width: 800px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 135%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 996px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    left: 124%;
  }
}

@media only screen and (min-width: 800px) {
.vertical-timeline--two-columns .vertical-timeline-element-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent; 
    border-left-color: #fff;
    
  }
}


@media only screen and (min-width: 800px){
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content::before, 
  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #fff;
  }
}

@media only screen and (min-width: 800px) {
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
    left: auto;
    right: 135%;
    text-align: right;
  }
}


@media only screen and (min-width: 996px) {
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
    right: 124%;
  }
}


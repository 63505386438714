.box {
  width:100%;
  padding-bottom:100%;
  overflow:hidden;
  position:relative;
 
}

.box .square-thumb {
  @extend %transition-medium;
  object-fit: cover;
  position:absolute;
  width:auto;
  min-width:100%;
  min-height:100%;
  max-width: 120%;
  max-height: 120%;
  bottom:0 ;
  opacity: 1;



  &.thumb-old {
    opacity: 1;
    z-index: 0;
  }

  &.thumb-new {
    z-index: 1;
    opacity: 1;
  }

  &.thumb-hidden {
    opacity: 0;
  }

  
  
}



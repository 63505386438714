

.image-crop, .image-crop-sm { 
  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  img { max-height: initial; /* Maybe optional. See note below */ }
}
.image-crop {
  max-height: 600px;
}

@media only screen and (max-width: 767px) and (orientation:landscape){ 
  .image-crop {
    max-height: 100vh;
  }
}

.image-crop-sm {
  max-height: 300px;
}

@media only screen and (max-width: 767px) {
  .image-caption {
    position: absolute;
    bottom : 0;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3));
    p {
      margin: 0;
      color: $white;

      .source {
        color: $grey-light;
        
      }
    }
  }
}

.source {
  color: $grey;
  font-size: 0.7rem; 
  /*display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width : 150px;*/
}


.date-legend {
 
  position: absolute;
  z-index: 1;
  padding: 3px 10px;
  background-color: rgba($primary, 0.6);
  color: $white;
  &.float-right {
    right: 0;
  }
  
}

.overlay-image-wrapper {
  position: relative;
  
}
.overlay-image {
  position: absolute;
  
}
.clip-image-wrapper {
  position:relative;
  background: white;
  filter: drop-shadow(0px 10px 10px rgba(0,0,0,0.5));
  
}
.clip-image {
  -webkit-clip-path: polygon(22% 6%, 80% 15%, 69% 85%, 9% 84%);
  clip-path: polygon(20% 10%, 80% 10%, 80% 85%, 20% 85%);
  top: 5px; /* equal to border thickness */
  left: 5px; /* equal to border thickness */

  &:before {
    content: '';
    background: green;
  display: block;
  position: absolute;
  clip-path: polygon(20% 10%, 80% 10%, 80% 85%, 20% 85%);
  width: 100%;
  height: 100%;
  top: 1px;
  left: 1px;

  }
  

  
}

.loading-image {
  width: 800px;
  height: 800px;
 
}

.image-description {
  
  position:relative;
  h5 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.image-description-text {
  overflow: hidden;
  height: 150px;
}

#svg-wien {
  width: 100%; 
  height: auto;
  
  path {
    cursor: pointer;
    stroke: $grey-light; 
    fill: $grey-very-light;
    transition: all 0.4s ease-in-out;
  
  }
  path:hover {
    fill:  rgba($grey-light,0.7);
    
  }
}
$districts: 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23;
@each $district in $districts {

  .svg-#{$district}-highlight {
    #svg-#{$district} {
      stroke: $green;
      fill:  rgba($green,0.7);
    }
  }
} 

#svg-neubau:hover {
  transform-origin: center;
  transform: scale(1.1);
}

.rounded-image {
  $size: 60px;
  object-fit: cover;
  width:$size;
  height:$size;
}

.link-overlay {
  position: absolute;
  left: 30%;
  top: 20%;
  border: 2px solid red;
  z-index: 2;
  width: 20px;
  height: 20px;
}
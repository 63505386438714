.teaser-icon {
  $icon-size: 60px;
  text-align: center;
  margin: auto;
  font-size: 2em;
  position: absolute;
  top: -$icon-size/2;
  left: calc(50% - 30px);
  background: $green;
  border-radius: 50%;
  width: $icon-size;
  height: $icon-size;
  color: white;

}

.teaser {
  cursor: pointer;
  //margin-top: 60px!important;
  padding-top: 50px;
  text-align: center;
}
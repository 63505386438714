
.leaflet-container {
  font-family: inherit!important;
  font-size: 1rem !important;
  a {
    color: inherit !important;
   // -webkit-tap-highlight-color: rgba($primary, 0.4); 
  }
} 
.leaflet-popup-content-wrapper {
  border-radius: 0!important;
}
.leaflet-popup-content {
  padding: 0 !important;
  margin: 0 !important;
}
.leaflet-popup{
    bottom: -30px !important;

    min-width: 200px;
    max-width : 250px;
} 

.leaflet-popup-close-button {
  position:relative;
  z-index: 2;
}
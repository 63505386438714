@import '../../../../node_modules/bootstrap/scss/_variables.scss';

$offset-left: 15px;
$offset-top: 80px;
.map-sidebar {
  position: absolute;
  left: $offset-left;
  top: $offset-top;
 /* padding: 10px 20px;*/
  width: 450px;
  max-width: 100%;
  max-height: calc(100% - #{$offset-top} - 50px);
  z-index: 999999;
  //height: 100%;
  -webkit-transition: all 0.3s ease-in-out; /* Safari */
  transition: all 0.3s ease-in-out;
  transform: translateX(0);
  
  &.hidden {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
.map-nav-image-title {
  position: absolute;
    z-index: 3;
    top: 0;
    color: $white;
    padding: 10px !important;
}
  .map-sidebar {
    left: 0;
    bottom: 0;
    top: inherit;
    max-height: 70%;
    overflow: hidden;
    width: 100%;
  }
}
.map-nav.hidden {
  transform:translateX(-100%) translateX(-15px);
}

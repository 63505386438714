@mixin border-radius($args) {
  -webkit-border-radius: $args;
  -moz-border-radius: $args;
  border-radius: $args;
}

@mixin box-shadow($args) {
  -webkit-border-radius: $args;
  -moz-border-radius: $args;
  box-shadow: $args;
}

@mixin transition($duration) {
  -webkit-transition: all $duration ease-in-out; /* Safari */
  transition: all $duration ease-in-out;
}

@mixin gradient() {

}

@mixin linear-gradient() {
  $opacity: 0.4;
  $gradient-color: $green;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+40,000000+60,000000+100&0+0,$opacity+40,$opacity+60,0+100 */
background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,$opacity) 40%,rgba(0,0,0,$opacity) 60%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,$opacity) 40%,rgba(0,0,0,$opacity) 60%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */

}


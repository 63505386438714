.map-nav-icons {
  position: absolute;
  padding-right: 8px;
  text-align: right;
  font-size: 1.5em;
  width: 100%;
  padding-bottom: 20px;
  z-index: 3;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3));

  a {
    cursor: pointer;
    margin-left: 8px;
    color: white !important;
  }
}

%shadow-icon {
  color: white;
  text-shadow: 0px 0px 16px black;
  -webkit-filter: drop-shadow(0px 0px 5px #000 ); 
  filter: drop-shadow( 0px 0px 5px #000 );
  font-size: 3em;
  cursor: pointer;
}
.scroll-down-icon {
  @extend %shadow-icon;
  cursor: pointer;
  position: absolute;
  bottom: 1em;
  margin: 0 auto;
  width: 100%;
  
}

.scroll-to-top-icon {
  background: $grey-light;
  padding: 10px;
  font-size: $font-size-lg;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white !important;
  position: fixed;
  bottom: 20px;
  right: 20px;
  
}


.rounded-link {
  width: 100px;
  height: 100px;
  display: inline-flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  justify-content: center;
} 

/*

.map-nav-previous-next {
  position: absolute;
  top: calc(50%-40px);
  width: 100%;
  z-index: 2;
}*/
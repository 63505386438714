.map-nav .cropped {
  $text-height: 300px;
  $fade-out-height: $text-height - 50px;
  max-height: $text-height;
  &:after {
    height: $fade-out-height;
  }
}


.cropped {
  $text-height: 150px;
  $fade-out-height: $text-height - 50px;
  max-height: $text-height;

  
  overflow: hidden;
  position: relative;

  &:after {
    content:'';
    width: 100%;
    height: $fade-out-height;
    position:absolute;
    left:0;
    bottom:0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0px, $bg-color 90%, $bg-color);
  }

  &.cropped-white:after {
    background: linear-gradient(rgba(255, 255, 255, 0) 0px, $white 90%, $white);
  }
}  

.cropped-small {
  $text-height: 150px;
  $fade-out-height: $text-height - 50px;
  max-height: $text-height;

  
  overflow: hidden;
  position: relative;

  &:after {
    content:'';
    width: 100%;
    height: $fade-out-height;
    position:absolute;
    left:0;
    bottom:0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0px, $bg-color 70%, $bg-color);
  }

  &.cropped-white:after {
    background: linear-gradient(rgba(255, 255, 255, 0) 0px, $white 90%, $white);
  }
}  

.vertical-timeline-element-content, .map-nav{
  .cropped:after {
    background: linear-gradient(rgba(255, 255, 255, 0) 0px, $white 90%, $white);
  }
}
.btn-hero-section {
  background-color: rgba(0,0,0,0.2);
  span {
    letter-spacing: 0.1rem;
    font-size: $font-size-sm;
    text-transform: uppercase;
    text-shadow: 0px 0px 6px black;
    

  }
  &:hover  span {
    text-shadow: none;
  }
}
.btn.social-media {
  background: $btn-bg;
  border-radius: 50%;
  color:white;

  &.social-media-facebook:hover { 
    background-color: $color-facebook; 
 }

 &.social-media-twitter:hover {
  background-color: $color-twitter; 
}

&.social-media-whatsapp:hover {
  background-color: $color-whatsapp; 
}


&.social-media-email:hover {
  background-color: $color-email; 
}

  a {
    width: 100%;
    height: 100%;
    font-size:1.2em;
    
    //background-color: $grey-light;
    display: inline-block;
  }

  + .social-media {
    margin-left: 10px;
  }
}

.search-field {
  position: relative;
}
.btn-reset-input {
  position:absolute;
  right: 0;
  top:3px;
}


@import '../../../../node_modules/bootstrap/scss/variables';
$imagelinkPadding : 10px;

.image-navigation-link {
  position: absolute;
  top: 0; //calc(50% - 24px);
  
  width: 40px;
  height: calc(100% - 0px);
  z-index:2;
  font-size: 20px;
  color: white;

  background-repeat: no-repeat;
  background-position: center;

  &.image-navigation-link-left {
    //left: $imagelinkPadding;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABHklEQVRYhe3YvU0DQRCG4ffQBS7AMSJCVGBCN0CMKACJGiiAGkjIHbkAynAFliNEB0gWfEQIYdmG2fk5gvsuO2lGz06yq+mE+M85GRrwW0agNyMQ0SMWiDXioqE+8ZN6SUt951XSuaVHJe4rj8MDD+PWkk6HBQbi4oHBuFhgAi4OmISLASbi/MDjuDP34V3AAlw78DBuE4lrAxbi7MBiXAvwYQ/uJQsnZH5urYCPnX9TYGZ+Rv015jNJt5Led6a4lXSdMcG2skJke2kR0ldegPS3SEbGnDMRGQNMRMYBk5CxwARkPDAYmQM8jryy9MlbfXQ8AXf8vLt7YG7qkzbB/ZN8ljSx1HeiYMMqboBL4J6ON0tpDdCRcT/ozQj05hM+1ndN+tT3eAAAAABJRU5ErkJggg==');
    
  }

  &.image-navigation-link-right {
    //right: $imagelinkPadding;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABE0lEQVRYhe3YsQ3CQAwF0H8oygwRFWIEaipqZoExKKhgAlZIxRhUFCgVYgqqT4FQBIkEvrNNFOW7i2TnnZtEFwiiyxn9G/AtAzA1PQQSUxAViBJEbmD6fJ+gyAnJG+scSeaiGcKSArdsxhQpBRYkL55IeYszMq7NERnf6oRMayfH1sj0EU9kZYVUOKMtUgdoiNQDGiF1gQZIfaAy0gZYI68tyJ1kjuX/4AxA0fL8JJpitL0lyXvL9lbSWZ3G6QOVcbpAA5we0AinAzTEpQONcWlAB1w80AkXB3TEyYHkwhNHyL/Fc6Bx3bFGwF445/cIN5iRLD0296pACG9YiQzABsAZAQeDnb1FDnROD+8HnTMAU/MAW+d1WLkFhPYAAAAASUVORK5CYII=');
    text-align: right;
    right: 0;
  }

  /*
  background-color: rgba(0,0,0,0.5);
  border-radius: 50%;
  text-align: center;
  vertical-align: middle
  */
  &:hover, &:active {
    color: white;
  }
  
}
a  {
  h2, h3, h4, h5, h6 {
    color: black;
    
  }
}

  h1, h2, h3, h4, h5, h6 {
    a {
      color: black;
    text-decoration: none !important;
    &:active, &:hover {
      color: black;
      text-decoration: none !important;
    }
  }
}

.image-navigation-link, .map-nav-image-title, .no-text-decoration  {
  text-decoration: none;
  color: $body-color;
  &:hover, &:active {
    text-decoration: none!important;
    color: $body-color;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "md")) {

  .map-nav-image-title a  {
    
    h2, h3, h4, h5, h6 {
        color: white;
    }
  }
  .navigate-back {
    margin: 0 !important;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3));
    width: 100%;
    position: absolute;
    z-index:2;
    a {
      color: $white;
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "md")) and (orientation:landscape){ 
  .hide-in-landscape {
    display: none;
  }
}

.navbar-dark {
  background: rgba(255, 255, 255, 0);
}

.navbar-light {
  background: rgba(255, 255, 255, 1);
}

.navbar.fixed-top, .navbar-light.fixed-top {
  z-index: 1031;
}
/*
.navbar-brand {

  font-family: 'Crimson Text', serif;
  font-size: 1.6rem
}*/
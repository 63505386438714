.banner {
  //background-color:$green;
  background-image : linear-gradient(
    rgba($green, 0.8), 
    rgba($green, 0.8)
  ),
  url('/images/1010 Neuer Markt1.jpg');
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  

  
  
}
.banner-home, .date-range {
  font-size: 2em;
  color:$white;
  a {
    color: $white !important;
  }


}
.banner-icon {
  font-size: 2em;

  &:hover {
    text-shadow: 0px 0px 16px black;
    
  }
}

.search-form-desktop {
  padding-top: 100px;

  &:before {
    @extend .banner;
    content: ' ';
    height: 293px;
    width: 100%;
    position: absolute;
    top: -100px;
  }
}

.darken {
  position: relative;
}
.darken:after {
  content: ' ';
  left: 0;
  top:0;
  width:100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,0.4);
}
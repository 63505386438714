.count {
  color: white;
  width: 100px;
  height: 100px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  
}

.count-fb {
  background-color: rgba($color_facebook,0.2); 
}

.count-instagram {
  background-color:  rgba($color_instagram,0.2); 
}

body, html {
  
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  background-color: $bg-color;
}

//does not work with position: sticky of header
html, body {
  width: auto!important; 
  /*overflow-x: hidden!important*/
} 

* {
  box-sizing: border-box;
}

.small-text {
  font-size: 1rem;
}

.full-screen {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}

strong {
  font-weight: 600;
}


.navigation-map {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
}


.link {
  cursor: pointer;
}

.link-unstyled-text {
  color: $grey-dark;
  &:hover {
    color: $grey-dark;
  }
}
.link-unstyled {
  text-decoration: none;
  &:active, &:hover {
  text-decoration: none;
  }
}
.footer {
  
  a {
    color: $color-footer;
    &:hover {
      color: $color-footer;
    }
  }
}

.footer-icon {
  font-size: 1.6rem;
  line-height: 1rem;
}

.hero-section {
  background-image: url("/images/fortepan_42045.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  //background-attachment: fixed;
  box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.1);
  height: 100vh;

/* Flexbox */
  display: flex;
  align-items: center;
  object-fit: cover;
  position: relative; 

  text-align: center;
  color: white; 
}


.hero-section-small {
  height: 50vh;
}

.hero-section-tour {
  height: 70vh;
  min-height: 500px;
  align-items: flex-end;
  position: relative

}
.hero-section-tour h1, 
.hero-section-tour p {
  position: relative;
  z-index: 2;
} 


.hero-section-tour:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  background: -webkit-linear-gradient(top,rgba(0,0,0,0) 6%,rgba(0,0,0,0.9));
  background: -moz-linear-gradient(top,rgba(0,0,0,0) 6%,rgba(0,0,0,0.9));
  background: -o-linear-gradient(top,rgba(0,0,0,0) 6%,rgba(0,0,0,0.9));
  
  background: linear-gradient(to bottom,rgba(0,0,0,0) 6%,rgba(0,0,0,0.9));
  opacity: 0.8;
  width: 100%;
  height: 500px;
}


.hero-section .hero-section-content {
margin: auto;
color: white;


 h1 {
  font-family: 'Crimson Text', serif;
  text-shadow:0px 0px 16px black;
  
 }
}

.hero-section .subtitle  {
  text-shadow: 0px 0px 6px black;
  span {
  font-size: 1.2em;
 // text-shadow: 0px 0px 7px  black;
  padding: 10px 15px;
  text-transform: uppercase;
  //border-top: 2px solid white;
  //border-bottom: 2px solid white;
  //margin-top: 30px;
  letter-spacing: 0.2em;

  color: white;
  //background-color: white;

//  @include linear-gradient();
  }
}


.hero-source {
  bottom: 4px;
  right: 4px;
  font-size: 12px;
  color: rgba($white, 0.3);
  position: absolute;
  z-index: 1;
  &:hover {
    color: $white;
  }
}

.hero-section-social-media{
  
}
.sm-icon {
  margin: 0;
  padding: 0 !important; 
  font-size: 1rem !important;
}
.section-calendar  {
  color: $text-color!important;
}
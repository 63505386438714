
$transition-duration: 0.1s;
$transition-duration-medium: 0.4s;
$transition-duration-slow: 1s;


%transition {
  @include transition($transition-duration);
}

%transition-medium {
  @include transition($transition-duration-medium);
}

%transition-long {
  @include transition($transition-duration-slow);
}



.transition {
  @extend %transition;
}

.transition-medium {
  @extend %transition-medium;
}

.transition-long {
  @extend %transition-long;
}

.transition-zoom {
  @extend %transition;
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
    //box-shadow: 0 0 15px rgba(0,0,0,0.5);
    position: relative;
    z-index:1;
  }
}




.fade-in {
  @extend %transition-long;
  left: 0;
  opacity: 1;
  position: relative;
}

.not-faded-in {
  @extend %transition-long;
  opacity: 0;
  left: 0;
  position: relative;
}


.image-in-viewport {
  @extend %transition-long;
  transform: rotate(10deg);
  position:relative;
  left: 0%;
}

.image-not-in-viewport {
  @extend %transition-long;
  transform: rotate(20deg);
  position:relative;
  left: 10%;
}

.visible-from-left {
  @extend %transition-long;
  left: 0;
  opacity: 1;
  position: relative;
}

.not-visible-from-left {
  @extend %transition-long;
  opacity: 0;
  left: -5%;
  position: relative;
}



.visible-from-right {
  @extend %transition-long;
  left: 0;
  opacity: 1;
  position: relative;
}

.not-visible-from-right {
  @extend %transition-long;
  opacity: 0;
  left: 5%;
  position: relative;
}



.badge {
    padding: 0.5em;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
    border-radius: 0.1rem;
    background-color:rgba($grey-light, 0.3);
    color: $grey;

    &:hover, &:active {
      color: $grey;
      background-color: rgba($grey-light, 0.5);
    }
}

.date-badge {
  position: absolute; 
  line-height: 1em;
  width: 50px; 
  height : 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.date-badge-day {
  font-size: 1.1em;
  font-weight: bold;
}
.date-badge-month {
  font-size: 0.7em;
  letter-spacing: 0.04em;
  
}